var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{key:_vm.type === 'invoice' ? _vm.value.invoice_id : _vm.value.order_id,attrs:{"max-width":"900px"},model:{value:(
    _vm.paymentPreviewDialog[
      _vm.type === 'invoice' ? _vm.value.invoice_id : _vm.value.order_id
    ]
  ),callback:function ($$v) {_vm.$set(_vm.paymentPreviewDialog, 
      _vm.type === 'invoice' ? _vm.value.invoice_id : _vm.value.order_id
    , $$v)},expression:"\n    paymentPreviewDialog[\n      type === 'invoice' ? value.invoice_id : value.order_id\n    ]\n  "}},[_c('v-card',[_c('v-card-title',[_vm._v("Payments for "+_vm._s(_vm.type === "invoice" ? _vm.value.invoice_number : _vm.value.order_number)+" "),_c('v-spacer'),_c('v-btn',{staticClass:"float-right black--text",attrs:{"right":"","small":"","icon":""},on:{"click":function($event){return _vm.passModalClose($event)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1)],1),_c('v-container',{staticClass:"px-5"},[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.customer_payments,"hide-default-footer":"","loading":_vm.loadingPayments,"loading-text":"Loading payments...","item-key":"payment_id"},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"mt-5",attrs:{"text":"","dense":"","color":"error"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_vm._v(" There are no payments allocated to this "+_vm._s(_vm.type === "invoice" ? "invoice" : "pro-forma")+". ")]),_c('v-col',{staticClass:"shrink"},[_c('v-btn',{attrs:{"elevation":"0","color":"white"},on:{"click":function($event){return _vm.passModalClose()}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-close")]),_vm._v(" Close Preview")],1)],1)],1)],1)]},proxy:true},{key:"item.payment_amount",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatAsCurrency("R", item.payment_amount))+" ")]}},{key:"item.transaction_number",fn:function(ref){
    var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.pushRouteData(item)}}},[_vm._v(_vm._s(item.transaction_number))])]}},{key:"item.payment_id",fn:function(ref){
    var item = ref.item;
return [(item.attachments && item.attachments.length !== 0)?_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.openAttachment(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-attachment")])],1):_vm._e(),_c('v-btn',{attrs:{"small":"","icon":"","color":"error","loading":_vm.loadDelete[item.payment_id]},on:{"click":function($event){return _vm.unallocateCustomerPaymentFromInvoice(item, _vm.value)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1),_c('v-btn',{attrs:{"small":"","icon":"","loading":_vm.loadDownload[item.payment_id]},on:{"click":function($event){return _vm.downloadPaymentReceiptPDF(item, _vm.value)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-download")])],1)]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('v-divider')],1)],1),_c('v-row',[_c('v-col',[(
              _vm.$router.currentRoute.path === '/invoices' &&
              _vm.value.invoice_amount_due !== 0
            )?_c('v-btn',{staticClass:"float-right",attrs:{"color":"primary","loading":_vm.loadingPayments},on:{"click":function($event){_vm.$emit('passAllocateMorePayments', _vm.value.invoice_id);
              _vm.passModalClose($event);}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(" Allocate More Payments ")],1):_vm._e(),_c('v-btn',{staticClass:"float-right",attrs:{"text":""},on:{"click":function($event){return _vm.passModalClose($event)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-close")]),_vm._v("Close")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
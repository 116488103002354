<template>
  <v-dialog
    v-model="
      paymentPreviewDialog[
        type === 'invoice' ? value.invoice_id : value.order_id
      ]
    "
    max-width="900px"
    :key="type === 'invoice' ? value.invoice_id : value.order_id"
  >
    <v-card>
      <!--Title-->
      <v-card-title
        >Payments for
        {{ type === "invoice" ? value.invoice_number : value.order_number }}
        <v-spacer></v-spacer>
        <v-btn
          right
          small
          icon
          class="float-right black--text"
          @click="passModalClose($event)"
        >
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <!--payments-->
      <v-container class="px-5">
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="customer_payments"
              hide-default-footer
              :loading="loadingPayments"
              loading-text="Loading payments..."
              item-key="payment_id"
            >
              <!--Empty table-->
              <template v-slot:no-data>
                <v-alert text dense color="error" class="mt-5">
                  <v-row align="center">
                    <v-col class="grow">
                      There are no payments allocated to this
                      {{ type === "invoice" ? "invoice" : "pro-forma" }}.
                    </v-col>
                    <v-col class="shrink">
                      <v-btn
                        elevation="0"
                        color="white"
                        @click="passModalClose()"
                      >
                        <v-icon small left>mdi-close</v-icon>
                        Close Preview</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-alert>
              </template>
              <!--Format amount-->
              <template v-slot:item.payment_amount="{ item }">
                {{ formatAsCurrency("R", item.payment_amount) }}
              </template>
              <!--Click through to Bank Transaction-->
              <template v-slot:item.transaction_number="{ item }">
                <a @click="pushRouteData(item)">{{
                  item.transaction_number
                }}</a>
              </template>
              <!--Action Button-->
              <template v-slot:item.payment_id="{ item }">
                <!--View Attachments-->
                <v-btn
                  small
                  icon
                  v-if="item.attachments && item.attachments.length !== 0"
                  @click="openAttachment(item)"
                  ><v-icon small>mdi-attachment</v-icon></v-btn
                >
                <!--Delete Payment-->
                <v-btn
                  small
                  icon
                  color="error"
                  @click="unallocateCustomerPaymentFromInvoice(item, value)"
                  :loading="loadDelete[item.payment_id]"
                >
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
                <!--Download Receipt-->
                <v-btn
                  small
                  icon
                  @click="downloadPaymentReceiptPDF(item, value)"
                  :loading="loadDownload[item.payment_id]"
                >
                  <v-icon small>mdi-download</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <!--Allocate Additional Payments-->
            <v-btn
              color="primary"
              class="float-right"
              :loading="loadingPayments"
              v-if="
                $router.currentRoute.path === '/invoices' &&
                value.invoice_amount_due !== 0
              "
              @click="
                $emit('passAllocateMorePayments', value.invoice_id);
                passModalClose($event);
              "
            >
              <v-icon small left>mdi-plus</v-icon>
              Allocate More Payments
            </v-btn>
            <!----Close button-->
            <v-btn class="float-right" text @click="passModalClose($event)"
              ><v-icon small left>mdi-close</v-icon>Close</v-btn
            >
          </v-col>
        </v-row>
        <!---->
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import db from "../../../../components/firebaseInit";
import { formatAsCurrency } from "../../../../composables/external";
import mixin_CompanyProfile from "../../../../globalActions/mixin_CompanyProfile";
import { unallocateCustomerPaymentFromInvoice } from "../../data/external_invoices";
import { downloadPaymentReceiptPDF } from "../../data/external_PaymentReceiptPDF";
export default {
  name: "InvoicePaymentPreviewModal",
  props: ["paymentPreviewDialog", "value", "type"],
  mixins: [mixin_CompanyProfile],
  data() {
    return {
      loadDelete: {},
      toggleTest: false,
      loadDownload: {},
      customer_payments: [],
      loadingPayments: false,
      headers: [
        { text: "Payment #", value: "payment_number", align: "start" },
        { text: "Bank Transaction", value: "transaction_number" },
        { text: "Bank Account", value: "bank_account_name" },
        { text: "Date", value: "payment_date" },
        { text: "Amount", value: "payment_amount" },
        { text: "Actions", value: "payment_id", align: "right" },
      ],
    };
  },
  methods: {
    formatAsCurrency,
    unallocateCustomerPaymentFromInvoice,
    downloadPaymentReceiptPDF,
    passModalClose() {
      this.$set(
        this.paymentPreviewDialog,
        this.type === "invoice" ? this.value.invoice_id : this.value.order_id,
        false
      );
    },
    getCustomerPayments() {
      this.loadingPayments = true;
      this.customer_payments = [];
      // 1) Get payment info
      this.value.customer_payments.forEach((payment) => {
        const paymentRef = db.doc(`customer_payments/${payment.payment_id}`);
        paymentRef.get().then((paymentDoc) => {
          // 2) Get bank transaction from Database
          const bankRef = db.doc(
            `bank_transactions/${paymentDoc.data().transaction_id}`
          );
          bankRef.get().then((el) => {
            const data = {
              ...paymentDoc.data(),
              bank_account_name: el.data().bank_account_name,
              // Merge transaction data to payment
              attachments: el.data().attachments ? el.data().attachments : null,
            };
            this.customer_payments.push(data);
            this.loadingPayments = false;
          });
        });
      });
    },
    //
    pushRouteData(item) {
      const routeData = this.$router.resolve({
        name: "bank-statements",
        query: {
          search: item.transaction_number,
          start_date: item.payment_date,
          transaction_id: item.transaction_id,
        },
      });
      window.open(routeData.href, "_blank");
    },
    //
    openAttachment(item) {
      window.open(item.attachments[0].src, "_blank");
    },
  },
};
</script>
<style scoped>
.change-cursor:hover {
  cursor: pointer;
}
</style>